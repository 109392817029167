"use strict";Object.defineProperty(exports,Symbol.toStringTag,{value:"Module"});const a=require("@emotion/react"),l=require("./chunks/chunk-0ca3a92e.prod.cjs"),r={colorScheme:"light",colors:{grey600:"#141414",grey550:"#555555",grey500:"#a5a5a5",grey400:"#cccccc",grey300:"#e6e6e6",grey200:"#f0f0f0",grey100:"#f5f5f5",grey080:"#fafafa",white:"#ffffff",black:"#000000",blue:"#1E9EFF",red:"#DC3232",orange:"#ff6011",mint:"#0cd8a6",darkNavy:"#283241",gold:"#c8b464",purple:"#8e58ff",babyBlue:"#ebf8ff",paymentBlue:"#f1faff",bgBase:"#ffffff",bgTabBar:"#ffffff",bgElevated:"#ffffff",fillPrimary:"#141414",fillSecondary:"#787878",fillTertiary:"#787878",fillCarouselNum:"rgba(20, 20, 20, 0.2)",fillCta:"#141414",fillSelectText:"#aaa068",fillSetBand:"rgba(240, 240, 240, 0.9)",fillTopBox:"#373737",fillSkeletonElevated:"#fafafa",dimDialog:"rgba(0, 0, 0, 0.7)",dimThumb:"rgba(0, 0, 0, 0.04)"}},i={colorScheme:"dark",colors:{grey600:"#999999",grey500:"#666666",grey400:"#505050",grey300:"#373737",grey200:"#2d2d2d",grey100:"#242424",grey080:"#1e1e1e",grey060:"#1a1a1a",grey030:"#0e0e0e",white:"#ffffff",black:"#000000",blue:"#1496E6",red:"#8c1e14",orange:"#eb5a11",mint:"#00c8aa",darkNavy:"#313a49",gold:"#b4a050",purple:"#7847de",babyBlue:"#28404d",paymentBlue:"#f1faff",bgBase:"#1a1a1a",bgTabBar:"#0e0e0e",bgElevated:"#242424",fillPrimary:"#ffffff",fillSecondary:"#666666",fillTertiary:"#999999",fillCarouselNum:"rgba(102, 102, 102, 0.2)",fillCta:"#f5f5f5",fillSelectText:"#aaa068",fillSetBand:"rgba(240, 240, 240, 0.9)",fillTopBox:"#373737",fillSkeletonElevated:"#2d2d2d",dimDialog:"rgba(0, 0, 0, 0.7)",dimThumb:"rgba(0, 0, 0, 0.04)"}},t=r,c=a.css`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0, 0, 0, 0);
`,n=(e,o)=>a.css`
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: ${e};
  -webkit-box-orient: vertical;

  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  word-wrap: break-word;
  white-space: normal;

  /* Fallback for IE */
  max-height: calc(${o} * ${e});
  line-height: ${o};
`;var f=(e=>(e[e.LEVEL_TOASTMESSAGE=1e4]="LEVEL_TOASTMESSAGE",e[e.LEVEL_MODAL=9e3]="LEVEL_MODAL",e[e.LEVEL_OVERLAY=8e3]="LEVEL_OVERLAY",e[e.LEVEL_HEADER=7e3]="LEVEL_HEADER",e[e.LEVEL_TOOLTIP=6e3]="LEVEL_TOOLTIP",e[e.LEVEL_DROPDOWN=5e3]="LEVEL_DROPDOWN",e[e.LEVEL_BASE=0]="LEVEL_BASE",e[e.LEVEL_BELOW=-1]="LEVEL_BELOW",e))(f||{});const E=l.touchDevice(a.css`
  display: none;
`),b=a.css`
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 0;
    display: none !important; // 윈도우 크롬 등
  }
  & {
    overflow: -moz-scrollbars-none;
  }
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox 64 */
`,s=a.css`
  :focus {
    outline: none;
  }
  :active {
    outline: none;
  }
`;exports.BreakPoint=l.BreakPoint;exports.between=l.between;exports.greaterThan=l.greaterThan;exports.hover=l.hover;exports.orBelow=l.orBelow;exports.touchDevice=l.touchDevice;exports.ZIndexLayer=f;exports.a11y=c;exports.clearOutline=s;exports.darkTheme=i;exports.defaultTheme=t;exports.displayNoneForTouchDevice=E;exports.lightTheme=r;exports.lineClamp=n;exports.scrollBarHidden=b;
