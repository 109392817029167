var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3.9.25-25e96d0"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/books-frontend/p/25e96d";

import { initSentry } from './src/utils/sentry/initSentry';

const SAMPLE_RATE = process.env.RUN_MODE === 'production' ? 0.3 : 1.0;

// partials에서 센트리 초기화 방지
if (window.RIDI_SENTRY_ENABLED !== false) {
  initSentry(SAMPLE_RATE);
}
