import { initPerfume, IPerfumeData } from 'perfume.js';
import { useEffect } from 'react';

type ExcludeFunctionAndUndefined<T> = T extends undefined | (() => void) ? never : T;

function sendToGTM(metricName: string, data: IPerfumeData) {
  if (typeof data === 'number') {
    window.dataLayer?.push({
      event: 'web-vitals',
      event_category: 'Perfume.js',
      event_action: metricName,
      event_value: data,
    });
    return;
  }

  type IPerfumeDataEntries = Entries<UnionToIntersection<typeof data>>;
  type IPerfumeDataFilteredKey = IPerfumeDataEntries[number][0];
  type IPerfumeDataFilteredValue = ExcludeFunctionAndUndefined<IPerfumeDataEntries[number][1]>;
  type IPerfumeDataFilteredEntry = [IPerfumeDataFilteredKey, IPerfumeDataFilteredValue];

  const perfumeJsFilteredEntries = Object.entries(data).filter(
    entry => typeof entry[1] !== 'undefined' && typeof entry[1] !== 'function',
  ) as IPerfumeDataFilteredEntry[];

  perfumeJsFilteredEntries.forEach(([key, value]) => {
    window.dataLayer?.push({
      event: 'web-vitals',
      event_category: 'Perfume.js',
      event_action: metricName,
      event_label: key,
      event_value: value,
    });
  });
}

export const usePerfumeJs = () => {
  useEffect(() => {
    initPerfume({
      analyticsTracker: ({ metricName, data }) => {
        switch (metricName) {
          case 'navigationTiming':
            if (data && typeof data !== 'number' && 'timeToFirstByte' in data && data.timeToFirstByte) {
              sendToGTM('navigationTiming', data);
            }
            break;
          case 'networkInformation':
            if (data && typeof data !== 'number' && 'effectiveType' in data && data.effectiveType) {
              sendToGTM('networkInformation', data);
            }
            break;
          case 'storageEstimate':
            sendToGTM('storageEstimate', data);
            break;
          case 'TTFB':
            sendToGTM('timeToFirstByte', data);
            break;
          case 'RT':
            sendToGTM('redirectTime', data);
            break;
          case 'FCP':
            sendToGTM('firstContentfulPaint', data);
            break;
          case 'FID':
            sendToGTM('firstInputDelay', data);
            break;
          case 'LCP':
            sendToGTM('largestContentfulPaint', data);
            break;
          case 'CLS':
            sendToGTM('cumulativeLayoutShift', data);
            break;
          case 'INP':
            sendToGTM('interactionToNextPaint', data);
            break;
          case 'TBT':
            sendToGTM('totalBlockingTime', data);
            break;
          default:
            sendToGTM(metricName, data);
            break;
        }
      },
    });
  }, []);
};
