import { ServerRequest } from '@/base/interfaces/ServerRequest';
import { NavigationModel } from '@/models/backendsApi/v2/Navigation/NavigationModel';
import { getCacheKey, makeServiceAction } from '@/services/baseService';
import { type FeatureFlag, getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { makeCallAPIMethod } from '../../backendsApiService';

export type NavigationError = {
  success: false;
  message: string;
  data: null;
};

const makeCacheKey = () => getCacheKey('backendsApi', 'v2/navigation', {});

export const fetchNavigation = async (
  reqParams: { featureFlag: FeatureFlag } | undefined,
  req: ServerRequest | undefined,
) =>
  makeServiceAction<void, NavigationError, NavigationModel>(
    makeCallAPIMethod(
      !!getFeatureFlagValueByKey({
        featureFlag: req?.FeatureFlag || reqParams?.featureFlag,
        featureFlagKey: 'web-internal-backendsapi-request-20240710',
      }),
    )(NavigationModel),
    () => ({
      method: 'GET',
      pathname: '/v2/navigation/web',
      preferNonProxied: true,
    }),
  )(NavigationModel, makeCacheKey)(undefined, req);
