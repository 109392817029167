import { ServerRequest } from '@/base/interfaces/ServerRequest';
import { FeatureFlagModel } from '@/models/backendsApi/v2/FeatureFlag/FeatureFlagModel';
import { ServiceMethod } from '@/services/baseService';
import { type FeatureFlag, getFeatureFlagValueByKey } from '@/utils/featureFlag';

import { makeCallAPIMethod } from '../../backendsApiService';

type FeatureFlagError = {
  success: false;
  message: string;
  data: null;
};

export type FeatureFlagRequest = {
  featureFlag?: FeatureFlag;
  query?: undefined;
};

export const featureFlag: ServiceMethod<FeatureFlagRequest, FeatureFlagModel, FeatureFlagError> = async (
  _reqParams?,
  req?,
) => {
  const call = makeCallAPIMethod(
    !!getFeatureFlagValueByKey({
      featureFlag: [
        {
          flagKey: 'web-internal-backendsapi-request-20240710',
          flagValue: true,
        },
      ],
      featureFlagKey: 'web-internal-backendsapi-request-20240710',
    }),
  )(FeatureFlagModel);
  return call<FeatureFlagRequest, FeatureFlagError, FeatureFlagModel>(
    {
      method: 'GET',
      pathname: '/v2/feature-flag',
      preferNonProxied: true,
    },
    req,
  );
};

export function fetchFeatureFlagByKey(key: string, req?: ServerRequest) {
  const call = makeCallAPIMethod(true)(FeatureFlagModel);
  return call<FeatureFlagRequest, FeatureFlagError, FeatureFlagModel>(
    {
      method: 'GET',
      pathname: `/v2/feature-flag/${key}`,
      preferNonProxied: true,
    },
    req,
  );
}
