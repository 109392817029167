import createCache, { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { NAMESPACE } from '@ridi-web/common';
import { ReactNode } from 'react';
import { prefixer } from 'stylis';

import { isServer } from '@/services/baseService';

export const createEmotionCache = (): EmotionCache => {
  const emotionCache = createCache({
    key: `${NAMESPACE.toLowerCase()}`,
  });

  // Compat 모드 -> SSR시 style append하지 않음, extractCritical 통해 style 생성
  // Regular 모드 -> SSR시 엘리먼트 뒤에 style append

  // * Compat 모드 사용 시 Streaming SSR를 버리는 댓가로 Hydration 시에 200ms의 Rendering 시간을 절약할 수 있음
  // * 또한 (first, nth, last)-child 사용 시 잠깐 스타일이 잘못 적용되는 이슈도 없게 되나,
  //   이는 원래 코드에서 사용하지 않기에 필요에 따라 Compat 모드를 버리고 Regular 모드로 전환 가능하다.
  emotionCache.compat = true;

  return emotionCache;
};

let emotionCache: EmotionCache | null = null;
export const getClientSideEmotionCache = (): EmotionCache | null => {
  if (isServer()) {
    return null;
  }

  if (!emotionCache) {
    emotionCache = createEmotionCache();
  }

  return emotionCache;
};

export const EmotionClientSideCacheProvider = ({ children }: { children: ReactNode }): ReactJSX.Element => {
  if (isServer()) {
    return <>{children}</>;
  }

  return <CacheProvider value={getClientSideEmotionCache() as EmotionCache}>{children}</CacheProvider>;
};

export const EmotionServerSideCacheProvider = ({
  cache,
  children,
}: {
  cache: EmotionCache;
  children: ReactNode;
}): ReactJSX.Element => {
  if (!isServer()) {
    return <>{children}</>;
  }

  return <CacheProvider value={cache}>{children}</CacheProvider>;
};

const createAdaptiveEmotionCache = (isMobile: boolean): EmotionCache => {
  const adaptiveEmotionCache = createCache({
    key: `${NAMESPACE.toLowerCase()}`,
    stylisPlugins: [
      prefixer,
      element => {
        if (element.props[0].startsWith('(force-max-width')) {
          if (isMobile) {
            // eslint-disable-next-line no-param-reassign
            element.value = '@media all';
          } else {
            // eslint-disable-next-line no-param-reassign
            element.value = '';
            // eslint-disable-next-line no-param-reassign
            element.children = [];
          }
        }
      },
    ],
  });

  return adaptiveEmotionCache;
};

/**
 * books-backend 의 적응형 페이지에 에서 orBelowWithForceMedia 사용하기 위한 별도의 EmotionCacheProvider
 *
 * AdaptiveEmotionCacheProvider 가 별도로 생성된 emotion cache 를 사용하므로,
 * ServerSide / ClientSide 모두 stylisPlugins 가 적용된 EmotionCache 를 사용하게 된다.
 *
 * @see src/components/styles/media.ts for orBelowWithForceMedia
 */
export const AdaptiveEmotionCacheProvider = ({ isMobile, children }: { isMobile: boolean; children: ReactNode }) => (
  <CacheProvider value={createAdaptiveEmotionCache(isMobile)}>{children}</CacheProvider>
);
