import {
  Array as RArray,
  Boolean as RBoolean,
  Literal as RLiteral,
  Null as RNull,
  Number as RNumber,
  Record as RRecord,
  Static as RStatic,
  String as RString,
  Union as RUnion,
} from 'runtypes';

import {
  RBackendsBookAuthor,
  RBackendsBookCover,
  RBackendsBookOriginalCoverSize,
} from '@/models/backendsApi/BackendsBookType';

import { RGroupSectionsItem } from '../../GroupSections/Items/GroupSectionsItemsType';
import {
  RBannerBadge,
  RGroupFetchUrl,
  RGroupMoreDetailUrl,
  RGroupSectionType,
  RGroupUnit,
  RQuickMenuType,
  RSectionBase,
  RSectionItemBase,
  RSectionItemBook,
  RSectionItemContentsBookBase,
  RSectionItemsFilter,
  SectionItemFilterTypes,
  SectionItemLayoutType,
  SectionLayoutType,
} from './ViewsBaseType';

/*
 * AiRecommendation Section
 */
export const RAiRecommendationItem = RSectionItemBase.extend({
  layout: RLiteral(SectionItemLayoutType.AiRecommendation),
  contents: RSectionItemContentsBookBase,
});
export const RAiRecommendationSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.AiRecommendation),
  contents: RRecord({
    title: RRecord({
      user_id: RString,
      postfix: RString,
    }).optional(),
  }),
  items: RArray(RAiRecommendationItem),
});
export type AiRecommendationItem = RStatic<typeof RAiRecommendationItem>;
export type AiRecommendationSection = RStatic<typeof RAiRecommendationSection>;

/*
 * BestSeller Section
 */

export const RBestSellersItemFilterKeys = RUnion(
  RLiteral(SectionItemFilterTypes.ADULT_EXCLUDE),
  RLiteral(SectionItemFilterTypes.ADULT_INCLUDE),
);

export const RBestSellerItem = RSectionItemBase.extend({
  layout: RLiteral(SectionItemLayoutType.BestSeller),
  contents: RSectionItemContentsBookBase,
});
export const RBestSellerSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.BestSeller),
  more_details: RRecord({
    resource_url: RString,
    path: RString,
  }).nullable(),

  contents: RRecord({
    default_filter: RBestSellersItemFilterKeys,
  }),
  items: RArray(RBestSellerItem),
  items_filter: RSectionItemsFilter.pick(SectionItemFilterTypes.ADULT_EXCLUDE, SectionItemFilterTypes.ADULT_INCLUDE),
});
export type BestSellerItem = RStatic<typeof RBestSellerItem>;
export type BestSellerSection = RStatic<typeof RBestSellerSection>;

/*
 * ReadingBook Section
 */
export const RReadingBookItem = RSectionItemBase.extend({
  layout: RLiteral(SectionItemLayoutType.ReadingBook),
  contents: RSectionItemContentsBookBase,
});
export const RReadingBookSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.ReadingBook),
  contents: RRecord({}).nullable(),
  items: RArray(RReadingBookItem),
});
export type ReadingBookItem = RStatic<typeof RReadingBookItem>;
export type ReadingBookSection = RStatic<typeof RReadingBookSection>;

/*
 * Group Section
 */
export const RGroup = RRecord({
  group_unit: RGroupUnit,
  fetch_url: RGroupFetchUrl,
  more_detail_url: RGroupMoreDetailUrl.optional(),
});
export const RGroupSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.Group),
  type: RGroupSectionType,
  contents: RRecord({
    title: RString,
    meta: RRecord({
      description: RString,
      title: RString,
    }).optional(),
  }),
  current_active_group_unit: RGroupUnit,
  groups: RArray(RGroup),
});
export const RGroupSectionItem = RSectionItemBase.extend({
  contents: RGroupSectionsItem,
});
export type Group = RStatic<typeof RGroup>;
export type GroupSection = RStatic<typeof RGroupSection>;
export type GroupSectionItem = RStatic<typeof RGroupSectionItem>;

/*
 * ImageGradientBanner Section(RiGrid)
 */
export const RImageGradientBannerSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.ImageGradientBanner),
  type: RLiteral('ImageGradientBanner'),
  contents: RRecord({
    cell: RRecord({
      id: RString,
    }),
    context: RRecord({
      section_id: RNumber,
    }),
    context_type: RString,
    query_path: RArray(RString),
  }),
});
export type ImageGradientBannerSection = RStatic<typeof RImageGradientBannerSection>;

/**
 * EventSectionItem
 *
 * Support EventVertical and EventHorizontal
 */

export const REventSectionItem = RSectionItemBase.extend({
  layout: RLiteral(SectionItemLayoutType.EventHorizontal),
  contents: RRecord({
    text_color: RString,
    main_image_url: RString,
    bg_color: RString,
    title: RString,
    copyrights: RArray(RString),
    landing_url: RString,
  }),
});
export type EventSectionItem = RStatic<typeof REventSectionItem>;

/*
 * EventHorizontal Section
 */
export const REventHorizontalSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.EventHorizontal),
  contents: RNull,
  items: RArray(REventSectionItem),
});
export type EventHorizontalSection = RStatic<typeof REventHorizontalSection>;

/*
 * EventVertical Section
 */
export const REventVerticalSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.EventVertical),
  contents: RNull,
  items: RArray(REventSectionItem),
});
export type EventVerticalSection = RStatic<typeof REventVerticalSection>;

/*
 * Keyword Section
 */
export const RKeywordItem = RSectionItemBase.extend({
  layout: RLiteral(SectionItemLayoutType.Keyword),
  contents: RRecord({
    name: RString,
    set_id: RString.nullable(),
    tag_id: RString,
  }),
});
export const RKeywordSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.Keyword),
  contents: RNull,
  items: RArray(RKeywordItem),
});
export type KeywordItem = RStatic<typeof RKeywordItem>;
export type KeywordSection = RStatic<typeof RKeywordSection>;

/*
 * NewRelease Section
 */
export const RNewReleaseItem = RSectionItemBase.extend({
  layout: RLiteral(SectionItemLayoutType.NewRelease),
  contents: RSectionItemContentsBookBase,
});
export const RNewReleaseSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.NewRelease),
  contents: RNull,
  items: RArray(RNewReleaseItem),
});
export type NewReleaseItem = RStatic<typeof RNewReleaseItem>;
export type NewReleaseSection = RStatic<typeof RNewReleaseSection>;

/*
 * OnePick Section
 */
export const ROnePickItem = RSectionItemBase.extend({
  layout: RLiteral(SectionItemLayoutType.OnePick),
  contents: RRecord({
    book_id: RString,
    cover: RBackendsBookCover,
    original_cover_size: RBackendsBookOriginalCoverSize.nullable(),
    title: RString,
    author: RBackendsBookAuthor,
    rating_score: RNumber,
    rating_count: RNumber,
    tags: RArray(RString),
    description: RString,
    event: RRecord({
      title: RString,
      landing_url: RString,
    }).nullable(),
    is_adult_only: RBoolean,
    blur_book_cover_image_url: RString.nullable(),
  }),
});
export const ROnePickSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.OnePick),
  contents: RRecord({
    is_logo_visible: RBoolean,
  }),
  items: RArray(ROnePickItem),
});
export type OnePickItem = RStatic<typeof ROnePickItem>;
export type OnePickSection = RStatic<typeof ROnePickSection>;

/*
 * Selection Section
 */
export const RSelectionItem = RSectionItemBase.extend({
  layout: RLiteral(SectionItemLayoutType.Selection),
  contents: RSectionItemContentsBookBase,
});
export const RSelectionSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.Selection),
  contents: RNull,
  items: RArray(RSelectionItem),
});
export type SelectionItem = RStatic<typeof RSelectionItem>;
export type SelectionSection = RStatic<typeof RSelectionSection>;

/*
 * SelectionCarousel Section
 */
export const RSelectionCarouselItem = RSectionItemBase.extend({
  layout: RUnion(
    RLiteral(SectionItemLayoutType.SelectionCarouselBookCover),
    RLiteral(SectionItemLayoutType.SelectionCarouselCharacter),
  ),
  contents: RRecord({
    book: RSectionItemBook,
    main_image_url: RString,
    bg_image_url: RString.optional(),
    title: RString,
    hooking_sentence: RString,
    landing_url: RString.nullable(),
    bg_color: RString.optional(),
    copyrights: RArray(RString),
  }),
});
export const RSelectionCarouselSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.SelectionCarousel),
  contents: RNull,
  items: RArray(RSelectionCarouselItem),
});
export type SelectionCarouselItem = RStatic<typeof RSelectionCarouselItem>;
export type SelectionCarouselSection = RStatic<typeof RSelectionCarouselSection>;

/*
 * SelectionCover Section
 */
export const RSelectionCoverSetContent = RRecord({
  main_image_url: RString,
  gradation_color: RString,
});
export const RSelectionCoverItem = RSectionItemBase.extend({
  layout: RLiteral(SectionItemLayoutType.SelectionCover),
  contents: RSectionItemContentsBookBase.extend({
    title_image_url: RString,
    set_contents: RArray(RSelectionCoverSetContent),
    copyrights: RArray(RString),
  }),
});
export const RSelectionCoverSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.SelectionCover),
  contents: RNull,
  items: RArray(RSelectionCoverItem),
});
export type SelectionCoverItem = RStatic<typeof RSelectionCoverItem>;
export type SelectionCoverSection = RStatic<typeof RSelectionCoverSection>;

/*
 * SelectionHookingSentence Section
 */
export const RSelectionHookingSentenceItem = RSectionItemBase.extend({
  layout: RLiteral(SectionItemLayoutType.SelectionHookingSentence),
  contents: RSectionItemContentsBookBase.extend({
    hooking_sentence: RString,
  }),
});
export const RSelectionHookingSentenceSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.SelectionHookingSentence),
  contents: RNull,
  items: RArray(RSelectionHookingSentenceItem),
});
export type SelectionHookingSentenceItem = RStatic<typeof RSelectionHookingSentenceItem>;
export type SelectionHookingSentenceSection = RStatic<typeof RSelectionHookingSentenceSection>;

/*
 * SelectionMultiline Section
 */
export const RSelectionMultilineItem = RSectionItemBase.extend({
  layout: RLiteral(SectionItemLayoutType.SelectionMultiline),
  contents: RSectionItemContentsBookBase,
});
export const RSelectionMultilineSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.SelectionMultiline),
  contents: RNull,
  items: RArray(RSelectionMultilineItem),
});
export type SelectionMultilineItem = RStatic<typeof RSelectionMultilineItem>;
export type SelectionMultilineSection = RStatic<typeof RSelectionMultilineSection>;

/*
 * SelectionOriginal Section
 */
export const RSelectionOriginalSetContent = RRecord({
  main_image_url: RString,
  gradation_color: RString,
  sub_sentence: RString,
  hooking_sentence: RString,
});
export const RSelectionOriginalItem = RSectionItemBase.extend({
  layout: RLiteral(SectionItemLayoutType.SelectionOriginal),
  contents: RSectionItemContentsBookBase.extend({
    set_contents: RArray(RSelectionOriginalSetContent),
    copyrights: RArray(RString),
    title_image_url: RString,
    is_updated: RBoolean,
  }),
});
export const RSelectionOriginalSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.SelectionOriginal),
  contents: RNull,
  items: RArray(RSelectionOriginalItem),
});
export type SelectionOriginalSetContent = RStatic<typeof RSelectionOriginalSetContent>;
export type SelectionOriginalItem = RStatic<typeof RSelectionOriginalItem>;
export type SelectionOriginalSection = RStatic<typeof RSelectionOriginalSection>;

/*
 * TopCarouselBanner Section
 */
export const RTopCarouselBannerItem = RSectionItemBase.extend({
  layout: RLiteral(SectionItemLayoutType.TopCarouselBanner),
  contents: RRecord({
    main_image_url: RString,
    gradation_color: RString.nullable(),
    title: RString,
    hooking_sentence: RString,
    landing_url: RString,
    copyrights: RArray(RString),
    badges: RArray(RBannerBadge),
    has_closing_badge: RBoolean,
  }),
});
export const RTopCarouselBannerSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.TopCarouselBanner),
  contents: RNull,
  items: RArray(RTopCarouselBannerItem),
});
export type TopCarouselBannerItem = RStatic<typeof RTopCarouselBannerItem>;
export type TopCarouselBannerSection = RStatic<typeof RTopCarouselBannerSection>;

/*
 * TopCarouselBook Section
 */
export const RTopCarouselBookItem = RSectionItemBase.extend({
  layout: RLiteral(SectionItemLayoutType.TopCarouselBook),
  contents: RSectionItemContentsBookBase.extend({
    hooking_sentence: RString,
  }),
});
export const RTopCarouselBookSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.TopCarouselBook),
  contents: RNull,
  items: RArray(RTopCarouselBookItem),
});
export type TopCarouselBookItem = RStatic<typeof RTopCarouselBookItem>;
export type TopCarouselBookSection = RStatic<typeof RTopCarouselBookSection>;

/*
 * TopCarouselCover Section
 */
export const RTopCarouselCoverItem = RSectionItemBase.extend({
  layout: RLiteral(SectionItemLayoutType.TopCarouselCover),
  contents: RRecord({
    mobile_main_image_url: RString,
    pc_main_image_url: RString,
    gradation_color: RString.nullable(),
    title: RString,
    hooking_sentence: RString,
    landing_url: RString,
    copyrights: RArray(RString),
    badges: RArray(RBannerBadge),
    has_closing_badge: RBoolean,
  }),
});
export const RTopCarouselCoverSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.TopCarouselCover),
  contents: RNull,
  items: RArray(RTopCarouselCoverItem),
});
export type TopCarouselCoverItem = RStatic<typeof RTopCarouselCoverItem>;
export type TopCarouselCoverSection = RStatic<typeof RTopCarouselCoverSection>;

/*
 * QuickMenu Section
 */
export const RQuickMenuItem = RSectionItemBase.extend({
  layout: RLiteral(SectionItemLayoutType.QuickMenu),
  contents: RRecord({
    bg_color: RString,
    main_image_url: RRecord({
      light: RString,
      dark: RString,
    }),
    title: RString,
    landing_url: RString,
    quick_menu: RQuickMenuType,
  }),
});
export const RQuickMenuSection = RSectionBase.extend({
  layout: RLiteral(SectionLayoutType.QuickMenu),
  contents: RNull,
  items: RArray(RQuickMenuItem),
});
export type QuickMenuItem = RStatic<typeof RQuickMenuItem>;
export type QuickMenuSection = RStatic<typeof RQuickMenuSection>;

export const RGenreHomeSection = RUnion(
  RAiRecommendationSection,
  RBestSellerSection,
  REventHorizontalSection,
  REventVerticalSection,
  RGroupSection,
  RImageGradientBannerSection,
  RKeywordSection,
  RNewReleaseSection,
  ROnePickSection,
  RSelectionSection,
  RSelectionCarouselSection,
  RSelectionCoverSection,
  RSelectionHookingSentenceSection,
  RSelectionMultilineSection,
  RSelectionOriginalSection,
  RReadingBookSection,
  RTopCarouselBannerSection,
  RTopCarouselBookSection,
  RTopCarouselCoverSection,
  RQuickMenuSection,
);

export const RGenreHomeSectionItem = RUnion(
  RAiRecommendationItem,
  RBestSellerItem,
  REventSectionItem,
  RKeywordItem,
  RGroupSectionItem,
  RNewReleaseItem,
  ROnePickItem,
  RSelectionItem,
  RSelectionCarouselItem,
  RSelectionCoverItem,
  RSelectionHookingSentenceItem,
  RSelectionMultilineItem,
  RSelectionOriginalItem,
  RReadingBookItem,
  RTopCarouselBannerItem,
  RTopCarouselBookItem,
  RTopCarouselCoverItem,
  RQuickMenuItem,
);

export type GenreHomeSection = RStatic<typeof RGenreHomeSection>;
export type GenreHomeSectionItem = RStatic<typeof RGenreHomeSectionItem>;

export const RSection = RUnion(RGenreHomeSection);
export const RSectionItem = RUnion(RGenreHomeSectionItem);
export type Section = RStatic<typeof RSection>;
export type SectionItem = RStatic<typeof RSectionItem>;
export type SectionContents = Section['contents'];
